<template>
  <div>
    <section class="mb-16">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-card class="px-4 py-4 pb-4">
            <v-card-title class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <v-icon class="mr-2"
                  >mdi mdi-account-supervisor-circle-outline</v-icon
                >
                <span class="font-weight-bold mr-2">Recepción total</span>
              </div>
              <v-btn
                class="ml-auto"
                color="secondary"
                @click="abrirModalEvaluacion"
                :disabled="tieneEvaluacion"
              >
                Evaluación del contratista
              </v-btn>
            </v-card-title>

            <h3 class="mt-8 mb-2">Datos de la recepción</h3>
            <v-divider />

            <v-row class="mt-4">
              <v-col cols="6">
                <v-menu transition="scale-transition" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        label="Fecha de recepción *"
                        prepend-inner-icon="mdi-calendar"
                        light
                        outlined
                        readonly
                        class="mr-4"
                        :error-messages="fechaRecepcionErrors"
                        :value="fecha_recepcionValue"
                        @change="$v.form.fecha_recepcion.$touch()"
                        @blur="$v.form.fecha_recepcion.$touch()"
                      />
                    </div>
                  </template>
                  <div @click.stop>
                    <vc-date-picker
                      v-model="form.fecha_recepcion"
                      mode="date"
                      locale="es"
                      :min-date="minDate"
                    />
                  </div>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu transition="scale-transition" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        label="Hora"
                        placeholder="Hora"
                        prepend-inner-icon="mdi mdi-clock-outline"
                        light
                        outlined
                        readonly
                        :error-messages="horaRecepcionErrors"
                        :value="hora_recepcionValue"
                        @change="$v.form.hora_recepcion.$touch()"
                        @blur="$v.form.hora_recepcion.$touch()"
                      />
                    </div>
                  </template>
                  <div @click.stop>
                    <vc-date-picker
                      hide-time-header
                      @input="updateHoraRecepcion()"
                      v-model="form.hora_recepcion"
                      mode="time"
                      locale="es"
                      class="fecha-form-time-picker"
                    />
                  </div>
                </v-menu>
              </v-col>
            </v-row>

            <v-col cols="12">
              <template>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(lote, index) in listaLote"
                    :key="index"
                  >
                    <v-expansion-panel-header>{{
                      lote.nombre
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <DataTableComponent
                        :headers="headers"
                        :items="lote.sub_procesos"
                        :inhabilitar_paginacion="true"
                        :tiene_paginacion="false"
                      >
                        <template v-slot:[`item.cantidad_recibida`]="{ item }">
                          <v-text-field
                            class="py-2"
                            v-model="item.cantidad_recibida"
                            outlined
                            disabled
                            dense
                            hide-details
                          />
                        </template>
                        <template v-slot:[`item.monto_a_pagar`]="{ item }">
                          <v-text-field
                            class="py-2"
                            v-model="item.monto_a_pagar"
                            outlined
                            disabled
                            dense
                            hide-details
                          />
                        </template>
                      </DataTableComponent>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>

            <v-row class="mt-4">
              <v-col cols="6">
                <v-file-input
                  accept="application/pdf"
                  clearable
                  class="py-2"
                  label="Acta de recepción *"
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="form.acta_recepcion"
                  :error-messages="actaRecepcionErrors"
                  @change="$v.form.acta_recepcion.$touch()"
                  @blur="$v.form.acta_recepcion.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-file-input
                  accept="application/pdf"
                  clearable
                  label="Acta de inconformidad (Opcional)"
                  outlined
                  class="py-2"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="form.acta_inconform"
                  @blur="$v.entregaEtapa.acta_inconform.$touch()"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-btn outlined block color="secondary" @click="regresar()">
            Regresar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn
            block
            type="button"
            @click="abrirModalGuardar()"
            color="secondary"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </section>
    <ConfirmationModalComponent
      :is-open="confirmarGuardarRecepcion"
      description="¿Desea guardar el documento?"
      @cancel="cancelarConfirmacion"
      @confirm="crearRecepcion"
      :is-loading="guardarRecepcion.isLoading"
    />
    <evaluacionModal
      :isOpen="modalEvaluacion"
      @close="modalEvaluacion = false"
      :proveedor="providerData"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import moment from "moment";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
  isResponseSuccesful,
} from "@/utils/loadable";
import { ConfirmationModalComponent } from "@/components/utils";
import { convertToFormData } from "@/utils/data";
import evaluacionModal from "./evaluacionModal.vue";

export default {
  name: "recepcionTotal",
  components: {
    ConfirmationModalComponent,
    MoneyTextFieldComponent,
    DataTableComponent,
    evaluacionModal,
  },
  validations() {
    return {
      form: {
        fecha_recepcion: { required },
        hora_recepcion: { required },
        acta_recepcion: { required },
      },
    };
  },
  data() {
    return {
      obsData: {},
      providerData: null,
      id_etapa: null,
      id_proveedor: null,
      id_contrato: null,
      minDate: new Date(),
      menu: null,
      modalEvaluacion: false,
      confirmarGuardarRecepcion: false,
      datosRecepcionRegistrados: false,
      listaLote: [],
      form: {
        id_tipo_recepcion: null,
        fecha_recepcion: null,
        hora_recepcion: null,
        acta_recepcion: null,
      },
      etapasLote: createLoadable([]),
      recepcion: createLoadable([]),
      criterios: createLoadable([]),
      evaluacion: createLoadable([]),
      guardarRecepcion: createLoadable(null),
      headers: [
        {
          text: "OBS",
          align: "center",
          value: "especificacion",
        },
        {
          text: "Unidad de medida",
          align: "center",
          value: "unidad_medida",
        },
      ],
    };
  },
  emits: ["close"],
  computed: {
    ...mapState("SeguimientoContractual", [
      "evaluacionDefault",
      "guardarEvaluacion",
      "obtenerCriterios",
    ]),

    buttonText() {
      return this.$route.params.id_etapa
        ? "Actualizar recepción"
        : "Registrar recepción";
    },
    fechaRecepcionErrors() {
      return new Validator(this.$v.form.fecha_recepcion).detect().getResult();
    },
    horaRecepcionErrors() {
      return new Validator(this.$v.form.hora_recepcion).detect().getResult();
    },
    actaRecepcionErrors() {
      return new Validator(this.$v.form.acta_recepcion).detect().getResult();
    },
    fecha_recepcionValue() {
      if (!this.form.fecha_recepcion) return;

      return moment(this.form.fecha_recepcion).format("DD-MM-YYYY");
    },
    hora_recepcionValue() {
      if (!this.form.hora_recepcion) return;
      return moment(this.form.hora_recepcion, "HH:mm:ss").format("HH:mm");
    },
    tieneEvaluacion() {
      return !!this.recepcion.data.resenia?.nota_promedio;
    },
  },
  methods: {
    ...mapActions("SeguimientoContractual", [
      "enviarEvaluacion",
      "resetForm",
      "agregarCalificacion",
      "cargarCriterios",
    ]),

    async cargarLotesEtapa() {
      toggleLoadable(this.etapasLote);
      const { data, headers } =
        await this.services.SeguimientoContractual.obtenerLotesEtapa(
          this.$route.params.id_orden
        );
      let obs = [];

      this.listaLote = data.data.map((lista) => ({
        ...lista,
        sub_procesos: lista.sub_procesos.map((lista_sub_procesos) => {
          let precio_unitario = Number(
            lista_sub_procesos.gspb.monto / lista_sub_procesos.gspb.cantidad
          );
          let cantidad_entregada = Number(
            lista_sub_procesos.gspb.monto / precio_unitario
          );
          let cantidad_total =
            Number(lista_sub_procesos.gspb.cantidad) -
            Number(lista_sub_procesos.gspb.cantidad_recibida);
          let monto_a_pagar = Number(cantidad_total * precio_unitario);

          obs.push({
            id_sub_proceso_obs: lista_sub_procesos.id,
            cantidad: Number(cantidad_entregada).toFixed(0) || 0,
            monto: Number(lista_sub_procesos.gspb.monto).toFixed(2),
          });

          return {
            cantidad_total: Number(cantidad_total).toFixed(0),
            especificacion: lista_sub_procesos.especificacion,
            cantidad: lista_sub_procesos.gspb.cantidad,
            unidad_medida: lista_sub_procesos.Presentacion.nombre,
            cantidad_recibida: cantidad_total,
            monto: lista_sub_procesos.gspb.monto,
            monto_a_pagar: Number(monto_a_pagar).toFixed(2),
            estado: cantidad_total > 0 ? "Pendiente" : "Recibido",
          };
        }),
      }));

      this.obsData = obs;

      setLoadableResponse(this.etapasLote, data, headers);

      if (this.$route.params.id_etapa) {
        return this.obtenerRecepcion();
      }
    },
    async obtenerRecepcion() {
      toggleLoadable(this.recepcion);
      const { data } = await this.services.ContratoService.getContratoEtapa(
        this.id_etapa
      );

      setLoadableResponse(this.recepcion, data);
      const recepcion = this.recepcion.data;
      this.form.fecha_recepcion = recepcion.fecha_recepcion;
      this.form.hora_recepcion = recepcion.hora_recepcion;
    },
    abrirModalGuardar() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.confirmarGuardarRecepcion = true;
    },

    abrirModalEvaluacion() {
      this.modalEvaluacion = true;
      this.cargarCriterios();
      this.fetchProviderData();
    },
    async crearRecepcion() {
      const evaluacionCalificacion = {
        observacion: this.guardarEvaluacion.observacion,
        adjunto: this.guardarEvaluacion.adjunto,
        notas: this.guardarEvaluacion.notas,
        promedioNota: this.guardarEvaluacion.promedioNota,
        id_contrato: Number(this.$route.params.id_orden),
        id_proveedor: Number(this.$route.params.idProveedor),
      };

      let montosAPagar = [];

      this.listaLote.forEach((lista) => {
        lista.sub_procesos.forEach((subProceso) => {
          montosAPagar.push({
            monto_a_pagar: subProceso.monto_a_pagar,
          });
        });
      });

      const dataTransformada = {
        ...this.form,
        ...evaluacionCalificacion,
        obs: this.obsData,
        id_tipo_recepcion: Number(this.$route.query.tipo_recepcion),
      };

      const formData = convertToFormData({ ...dataTransformada });

      if (this.id_etapa) {
        await this.services.ContratoService.actualizarEtapa(
          this.id_etapa,
          formData
        );
        this.confirmarGuardarRecepcion = false;
        this.regresar();
      } else {
        toggleLoadable(this.guardarRecepcion);
        const { data } =
          await this.services.SeguimientoContractual.guardarContratoRecepcion(
            this.$route.params.id_orden,
            formData
          );
        setLoadableResponse(this.guardarRecepcion, data, {
          skipOnSuccess: true,
          showAlertOnSuccess: true,
        });

        this.confirmarGuardarRecepcion = false;

        if (!isResponseSuccesful(data)) return;
        this.regresar();
      }
    },
    limpiarFormulario() {
      this.$v.form.$reset();
      this.$refs.form.reset();
      this.$v.form.adjunto.$reset();
    },
    cancelarConfirmacion() {
      this.confirmarGuardarRecepcion = false;
      this.limpiarFormulario();
    },
    updateHoraRecepcion(value) {
      const hora = moment(value).format("HH:mm:ss");
      this.form.hora_recepcion = hora;
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.$route.params.idProveedor
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
    regresar() {
      this.resetForm();

      this.$router.replace({
        path: `/proceso-compra/seguimiento-orden/${this.$route.params?.id_orden}/${this.$route.params?.idProveedor}`,
      });
    },
  },
  async created() {
    this.id_etapa = this.$route.params.id_etapa;
    if (!!!this.id_etapa) {
      this.headers = [
        ...this.headers,
        {
          text: "Cantidad total",
          align: "center",
          value: "cantidad_total",
        },
        {
          text: "Cantidad a recibir",
          align: "center",
          value: "cantidad_recibida",
          sortable: false,
        },
        {
          text: "Monto a pagar",
          align: "center",
          value: "monto_a_pagar",
          sortable: false,
        },
      ];
    } else {
      this.headers = [
        ...this.headers,
        {
          text: "Estado",
          align: "center",
          value: "estado",
          sortable: false,
        },
      ];
    }
    this.cargarLotesEtapa();
  },
  watch: {},
};
</script>
